import { useApolloClient } from "@apollo/client";
import { LegacyCard, Tabs, Modal, Spinner, Text, useIndexResourceState } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import {
  SubscriptionContractDiscountFromQuery,
  SubscriptionContractFromQuery,
  SubscriptionLineItemFromQuery,
} from "@smartrr/shared/shopifyGraphQL/subscriptionContracts";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { adjustToStoreBillingTime } from "@smartrr/shared/utils/dateUtils";
import { pluralize, pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { getQueryParamsForFilters } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import {
  bulkActivateCustomerPurchaseStates,
  bulkCancelCustomerPurchaseStates,
  bulkCustomerPurchaseStateSetNextBillingDate,
  bulkPauseCustomerPurchaseStates,
  loadCustomerPurchaseStates,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { updateShowSetUnpauseDate } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";


import { IUserSubscriptionCancellationProps } from "../../AdminChurnPreventionRoute/CancellationReasonsSettings";
import {
  HomePageSubscriptionColumns,
  IFailedBillingsInfo,
  PaginationContainer,
  checkIfOnlyActiveSubsAreSelected,
  getChangeSubscriptionStatusAction,
  getChangeSubscriptionStatusLabel,
  getFailedPurchaseStatesAndFailedBills,
  getRawContractData,
  getUserCancellationReasons,
  openSubscriptionDetailsInNewTab,
  parseSubscriptionForIndexTable,
  subscriptionCancellationColumns,
  subscriptionColumns,
  subscriptionFailureColumns,
} from "../../AdminSubscriptionDetailsRoute/libs";
import { exportSubscriptionsToCSV } from "../../AdminSubscriptionDetailsRoute/libs/utils/exportSubscriptionsToCSV";
import {
  SetNextOrderDateModal,
  SetUnpauseDateModal,
} from "../../AdminSubscriptionDetailsRoute/SubscriptionDetails/modals";
import { HomePageDateRangeWrapper, dateOptions } from "../constants";
import {
  HomePageTableTab,
  getFilterStatus,
  getOneWeekTimestampRange,
  getTomorrowTimestampRange,
  linkCancellationReasonToPurchaseState,
} from "../utils";
import { useSetNextOrderModalStore } from "../../AdminSubscriptionDetailsRoute/SubscriptionDetails/modals/SetNextOrderDateModal/useSetNextOrderModalStore";

interface Props {
  activeOrg: IOrganization | null;
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
  todayTimestamp: number;
  tomorrowTimestamp: number;
  startTimestamp: number;
  endTimeStamp: number;
}

export const HomeTables = ({
  endTimeStamp,
  startTimestamp,
  todayTimestamp,
  tomorrowTimestamp,
  activeOrg,
}: Props) => {
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);
  const {
    data: purchaseStates,
    totalCount,
    totalPages,
    isLoading,
  } = useSmartrrVendorSelector(state => state.purchaseStates);
  const { openModal: openSetNextOrderDateModal } = useSetNextOrderModalStore();
  const dispatch = useSmartrrVendorDispatch();
  const { Table, Pagination } = usePolarisTypedTable();

  const [tableProps, tableHandlers] = useTableHandlers("nextBillingDate", "ASC");

  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const [isOpenInNewTabConfirmationOpen, openInNewTabConfirmation, closeOpenInNewTabConfirmation] =
    useBoolean(false);
  const [isActivateConfirmationOpen, openActivateConfirmation, closeActivateConfirmation] = useBoolean(false);
  const [isCancelConfirmationOpen, openCancelConfirmation, closeCancelConfirmation] = useBoolean(false);
  const [rawContractLoading, setRawContractLoading] = useState(false);
  const [showRawContractModal, setShowRawContractModal] = useState(false);
  const [rawContractData, setRawContractData] = useState<{
    contract: SubscriptionContractFromQuery & { lines: SubscriptionLineItemFromQuery[] } & {
      discounts: SubscriptionContractDiscountFromQuery[];
    };
  } | null>(null);

  const [failedPurchaseStates, setFailedPurchaseStates] = useState<IFailedBillingsInfo[] | undefined>();

  const [userReasons, setUserReasons] = useState<IUserSubscriptionCancellationProps[]>([]);
  const [homePageColumnsState, setHomePageColumnsState] =
    useState<HomePageSubscriptionColumns>(subscriptionColumns);
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    purchaseStates as { [key: string]: any }[]
  );
  const [selectedTab, setSelectedTab] = useState(HomePageTableTab.ACTIVE);
  const [exportToCSVIsLoading, setExportToCSVIsLoading] = useState<boolean>(false);

  const openSetUnpauseDateModal = () => dispatch(updateShowSetUnpauseDate(true));

  const getFailedPurchaseStates = async () => {
    const uniquePurchaseStates = await getFailedPurchaseStatesAndFailedBills();

    if (uniquePurchaseStates.length) {
      setFailedPurchaseStates(uniquePurchaseStates);
    }
  };

  const exportToCsvWithLoadingState = useCallback(
    async (purchaseStates?: IPurchaseState[]) => {
      if (activeOrg) {
        setExportToCSVIsLoading(true);
        try {
          exportSubscriptionsToCSV(activeOrg, tableProps?.filter, purchaseStates);
        } catch (error) {
          if (isSuperUser) {
            console.log("Export to CSV error:", error);
          }
          addToast("Error exporting to CSV");
        }
        setExportToCSVIsLoading(false);
        addToast("CSV successfully created");
      }
    },
    [activeOrg, isSuperUser, addToast, tableProps]
  );

  const onPauseSubscription = useCallback(
    (ids: "all" | string[], unpauseDate: Date) => {
      dispatch(
        bulkPauseCustomerPurchaseStates({
          ids,
          tableProps,
          unpauseDate: adjustToStoreBillingTime(unpauseDate, activeOrg?.billingTime, activeOrg?.billingTimezone),
          addToast,
          queryFilterType: "filterIn",
        })
      );
    },
    [addToast, dispatch, tableProps]
  );

  const onActivateSubscription = useCallback(
    (ids: "all" | string[]) => {
      dispatch(
        bulkActivateCustomerPurchaseStates({
          ids,
          tableProps,
          addToast,
          queryFilterType: "filterIn",
        })
      );
    },
    [addToast, dispatch, tableProps]
  );

  const onCancelSubscription = useCallback(
    (ids: "all" | string[]) => {
      dispatch(
        bulkCancelCustomerPurchaseStates({
          ids,
          tableProps,
          addToast,
          queryFilterType: "filterIn",
        })
      );
    },
    [addToast, dispatch, tableProps]
  );

  const onSetNextDate = useCallback(
    async (ids: "all" | string[], date: Date) => {
      dispatch(
        bulkCustomerPurchaseStateSetNextBillingDate({
          ids,
          date: adjustToStoreBillingTime(date, activeOrg!.billingTime, activeOrg!.billingTimezone),
          tableProps,
          addToast,
          queryFilterType: "filterIn",
        })
      );
    },
    [activeOrg, tableProps, addToast, dispatch]
  );

  const handleExportAction = () => {
    {
      exportToCsvWithLoadingState(
        allResourcesSelected ? undefined : purchaseStates.filter(cps => selectedResources.includes(cps.id))
      );
      handleSelectionChange(SelectionType.All, false);
    }
  };

  const handleOpenInNewTabAction = () => {
    if (selectedResources.length > 3) {
      openInNewTabConfirmation();
      return;
    }
    openSubscriptionDetailsInNewTab(selectedResources, purchaseStates);
    handleSelectionChange(SelectionType.All, false);
  };

  const viewRawContractData = async (shopifyId: string | undefined) => {
    if (!shopifyId) {
      return;
    }
    setShowRawContractModal(true);
    setRawContractLoading(true);
    await getRawContractData(shopifyId, apolloClient)
      .then(({ lines, discounts, contract }) => {
        setRawContractLoading(false);
        setRawContractData({
          contract: {
            ...contract,
            lines,
            discounts,
          },
        });
      })
      .catch(error => {
        addToast(error);
      });
  };

  const promotedBulkActions = [
    {
      content: getChangeSubscriptionStatusLabel(purchaseStates, selectedResources),
      onAction: () =>
        getChangeSubscriptionStatusAction(
          purchaseStates,
          selectedResources,
          openActivateConfirmation,
          openSetUnpauseDateModal
        ),
      isLoading: exportToCSVIsLoading || isLoading,
    },

    checkIfOnlyActiveSubsAreSelected(selectedResources, purchaseStates)
      ? {
          content: "Set new order date",
          onAction: () => openSetNextOrderDateModal({}),
          isLoading: exportToCSVIsLoading || isLoading,
          disabled: isLoading,
        }
      : undefined,
    {
      content: "Open in new tab",
      onAction: handleOpenInNewTabAction,
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
    },
    isSuperUser && selectedResources.length === 1
      ? {
          content: "View raw subscription contract",
          onAction: () =>
            viewRawContractData(purchaseStates.find(cps => cps.id === selectedResources[0])?.shopifyId),
          isLoading: exportToCSVIsLoading || isLoading,
          disabled: isLoading,
        }
      : undefined,
  ];

  const bulkActions = [
    {
      content: "Export to CSV",
      onAction: handleExportAction,
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
    },

    {
      content: `Cancel ${pluralizeForPrefix(selectedResources.length, "subscription")}`,
      onAction: openCancelConfirmation,
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
      destructive: true,
    },
  ];

  const tabs = useMemo(
    () => [
      {
        id: "upcoming-orders",
        content: "Upcoming orders",
      },
      {
        id: "failed-transactions",
        content: "Failed transactions",
      },
      {
        id: "recent-cancellation",
        content: "Recent cancellation attempts",
      },
    ],
    []
  );

  const TableFilteringRange = (): JSX.Element => {
    let startDate, endDate;
    const format = (date: Date) => date.toLocaleDateString("en-US", dateOptions);
    if (selectedTab === HomePageTableTab.CANCELLED || selectedTab === HomePageTableTab.FAILED) {
      const { startTimestamp, endTimeStamp } = getOneWeekTimestampRange();
      startDate = endTimeStamp;
      endDate = startTimestamp;
    } else {
      const { todayTimestamp, tomorrowTimestamp } = getTomorrowTimestampRange();
      startDate = todayTimestamp;
      endDate = tomorrowTimestamp;
    }
    return (
      <HomePageDateRangeWrapper>
        <Text as="span" variant="bodyMd" color="subdued">
          {format(new Date(startDate))} - {format(new Date(endDate))}
        </Text>
      </HomePageDateRangeWrapper>
    );
  };

  useEffect(() => {
    getFailedPurchaseStates();
    getUserCancellations();
  }, []);

  const onTabChange = (tab: number) => {
    const filterIn = {
      ...(tab === HomePageTableTab.ACTIVE && {
        status: [getFilterStatus(HomePageTableTab.ACTIVE)],
        upcomingOrderDate: [`${todayTimestamp}`, `${tomorrowTimestamp}`],
      }),
      ...(tab === HomePageTableTab.FAILED && {
        status: [getFilterStatus(HomePageTableTab.FAILED)],
        shopifyId: failedPurchaseStates?.filter(ps => ps.shopifyId)?.map(ps => ps.shopifyId!),
      }),
      ...(tab === HomePageTableTab.CANCELLED && {
        status: [getFilterStatus(tab)],
        cancelledAt: [`${endTimeStamp}`, `${startTimestamp}`],
      }),
    };

    tableHandlers.setPageSize(5);
    tableHandlers.setPageNumber(tableProps.pageNumber);
    tableHandlers.setFilter(filterIn);
    tableHandlers.setOrderByField(tab === HomePageTableTab.CANCELLED ? "cancelledAt" : "nextBillingDate");
    tableHandlers.setOrderByValue(tab === HomePageTableTab.CANCELLED ? "DESC" : "ASC");

    setSelectedTab(tab);

    switch (tab) {
      case HomePageTableTab.ACTIVE: {
        setHomePageColumnsState(subscriptionColumns);
        break;
      }
      case HomePageTableTab.FAILED: {
        setHomePageColumnsState(subscriptionFailureColumns);
        break;
      }
      case HomePageTableTab.CANCELLED: {
        setHomePageColumnsState(subscriptionCancellationColumns);
        break;
      }
    }

    const queryParams = getQueryParamsForFilters(
      {
        orderBy: {
          [tableProps?.orderByField]: tableProps?.orderByValue ?? "ASC",
        },
      },
      filterIn,
      "filterIn"
    );

    dispatch(loadCustomerPurchaseStates({ queryParams }));
  };

  useEffect(() => {
    onTabChange(selectedTab);
  }, []);

  const getUserCancellations = async () => {
    const allResults = await getUserCancellationReasons();
    setUserReasons(allResults);
  };

  return (
    <LegacyCard>
      <div style={{ position: "relative" }}>
        <LegacyCard.Section flush>
          <TableFilteringRange />
          <Tabs tabs={tabs} selected={selectedTab!} onSelect={onTabChange}>
            <Table
              selectable
              data={parseSubscriptionForIndexTable(
                userReasons.length
                  ? linkCancellationReasonToPurchaseState(purchaseStates, userReasons)
                  : purchaseStates,
                [],
                activeOrg
              )}
              columns={homePageColumnsState}
              resourceName={{
                singular: "subscription",
                plural: "subscriptions",
              }}
              loading={isLoading}
              itemCount={totalCount}
              selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
              onSelectionChange={(selectionType, toggleType, selection) => {
                handleSelectionChange(selectionType, toggleType, selection);
              }}
              sortable={Object.values(homePageColumnsState)
                .filter(v => !v.disabled)
                .map(v => v.sorting)}
              onSort={(headingIndex: number, direction: "ascending" | "descending") => {
                tableHandlers.setOrderByField(
                  Object.values(homePageColumnsState).filter(value => !value.disabled)[headingIndex]
                    .paginatedValue
                );
                tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
              }}
              sortColumnIndex={Object.entries(homePageColumnsState).findIndex(
                ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
              )}
              sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
              bulkActions={bulkActions.filter((x): x is any => x != null)}
              promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
              emptyStateText="No subscriptions found"
              isRowSelected={id => isRowSelected(id, selectedResources)}
            />
          </Tabs>
        </LegacyCard.Section>
        <LegacyCard.Section subdued>
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > tableProps.pageNumber}
              hasPrevious={tableProps.pageNumber != 1}
              label={`Showing ${tableProps.pageNumber} of ${totalPages || 1}`}
              onNext={() => tableHandlers.setPageNumber(pageNumber => pageNumber + 1)}
              onPrevious={() => tableHandlers.setPageNumber(pageNumber => pageNumber - 1)}
            />
          </PaginationContainer>
        </LegacyCard.Section>
      </div>
      <React.Fragment>
        <ConfirmationWindow
          title={`Are you sure you want to open ${selectedResources.length}${
            allResourcesSelected ? "+" : ""
          } subscription(s) in new tabs?`}
          onReject={closeOpenInNewTabConfirmation}
          onConfirm={() => {
            openSubscriptionDetailsInNewTab(selectedResources, purchaseStates);
            handleSelectionChange(SelectionType.All, false);
          }}
          confirmationText="Yes"
          rejectionText="No"
          open={isOpenInNewTabConfirmationOpen}
        />

        {/* Pase subscription with date */}
        <SetUnpauseDateModal
          title={`Pause ${pluralize(selectedResources.length, "subscription")}`}
          bannerText={`Please select a future date for ${pluralize(
            selectedResources.length,
            "subscription"
          )} to auto-resume. The customer will be notified by email one week in advance of the unpause date.`}
          onConfirm={date => {
            onPauseSubscription(allResourcesSelected ? "all" : selectedResources, date);
            handleSelectionChange(SelectionType.All, false);
          }}
        />

        <ConfirmationWindow
          title={`Are you sure you want to activate ${selectedResources.length}${
            allResourcesSelected ? "+" : ""
          } subscription(s)?`}
          onReject={closeActivateConfirmation}
          onConfirm={() => {
            onActivateSubscription(allResourcesSelected ? "all" : selectedResources);
            handleSelectionChange(SelectionType.All, false);
            closeActivateConfirmation();
          }}
          confirmationText="Yes"
          rejectionText="No"
          open={isActivateConfirmationOpen}
        >
          It might take a few minutes to process this request.
        </ConfirmationWindow>

        <ConfirmationWindow
          title={`Are you sure you want to cancel ${selectedResources.length}${
            allResourcesSelected ? "+" : ""
          } subscription(s)?`}
          onReject={closeCancelConfirmation}
          onConfirm={() => {
            onCancelSubscription(allResourcesSelected ? "all" : selectedResources);
            handleSelectionChange(SelectionType.All, false);
            closeCancelConfirmation();
          }}
          confirmationText="Yes"
          rejectionText="No"
          open={isCancelConfirmationOpen}
        >
          It might take a few minutes to process this request.
        </ConfirmationWindow>

        <SetNextOrderDateModal
          title={`Select the next order date for ${selectedResources.length > 1 ? "these" : "this"} ${pluralize(
            selectedResources.length,
            "subscription"
          )}`}
          bodyElement={<span>It might take a few minutes to process this request.</span>}
          initialISODate={new Date().toDateString()}
          onConfirm={date => {
            onSetNextDate(allResourcesSelected ? "all" : selectedResources, date);
            handleSelectionChange(SelectionType.All, false);
          }}
          allowSendNow={false}
        />

        <Modal
          title="Shopify data"
          open={showRawContractModal}
          onClose={() => {
            setShowRawContractModal(false);
            setRawContractData(null);
          }}
          primaryAction={{
            content: "Close",
            onAction() {
              setShowRawContractModal(false);
              setRawContractData(null);
            },
          }}
          secondaryActions={[
            {
              content: "Copy",
              onAction() {
                copyToClipboard(JSON.stringify(rawContractData, null, 2), `Data copied to clipboard`, addToast);
              },
            },
          ]}
        >
          <Box p={1}>
            {rawContractLoading ? (
              <Box justifyContent="center" alignItems="center" style={{ width: "100%" }}>
                <Spinner />
              </Box>
            ) : (
              <Box>
                <pre style={{ fontFamily: "monospace", margin: 0, whiteSpace: "pre-wrap" }}>
                  {JSON.stringify(rawContractData, null, 2)}
                </pre>
              </Box>
            )}
          </Box>
        </Modal>
      </React.Fragment>
    </LegacyCard>
  );
};
