import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseState, IPurchaseStateCSV } from "@smartrr/shared/entities/PurchaseState";
import { SmartrrFilterType } from "@smartrr/shared/utils/paginatedQuery";

import { getQueryParamsForFilters } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { createAndDownloadCsv } from "@vendor-app/utils/createAndDownloadCsv";
import { exportPurchaseStatesToCsvRows } from "@vendor-app/utils/exportPurchaseStatesToCsvRows";
import { mapPurchaseStatesWithDelay } from "@vendor-app/utils/mapSubscriptionWithDelay";

export const exportSubscriptionsToCSV = async (
  activeOrg: IOrganization | null,
  filter: SmartrrFilterType,
  purchaseStates?: IPurchaseState[]
) => {
  let rows: IPurchaseStateCSV[] = [];
  if (purchaseStates?.length) {
    rows = await mapPurchaseStatesWithDelay(purchaseStates, activeOrg);
  } else {
    const newQueryParams = getQueryParamsForFilters({}, filter);

    rows = await exportPurchaseStatesToCsvRows(activeOrg, undefined, newQueryParams);
  }

  createAndDownloadCsv(rows, "subscriptions");
};
