import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";

export const checkIfOnlyActiveSubsAreSelected = (
  selectedResources: string[],
  purchaseStates: IPurchaseStateWithCustomerRelationship[]
) => {
  return (
    purchaseStates.findIndex(ps => selectedResources.includes(ps.id) && ps.purchaseStateStatus !== "ACTIVE") == -1
  );
};
