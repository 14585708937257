import { Badge, Banner, Button, Layout, LegacyCard, LegacyStack, Link, List, Page, Text } from "@shopify/polaris";
import { ClockMajor, ExternalMinor } from "@shopify/polaris-icons";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { getDaysBetweenDates } from "@smartrr/shared/utils/dateUtils";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { loadAccountPlanConfigs, syncAccountPlans } from "@vendor-app/app/_state/actionCreators/accountPlans";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { CSMBlock } from "./components/CSMBlock";
import { HomeTables } from "./components/HomeTables";
import { links } from "./constants";
import { getOneWeekTimestampRange, getTomorrowTimestampRange } from "./utils";
import { SupportFormContainer } from "../../_sharedComponents/SupportForm";
import { useActiveOrganizationSelector } from "../../_state/reducers/organizations";
import { getSellingPlanGroups } from "../AdminSubscriptionDetailsRoute/libs";
import { RSSBlock } from "../AdminSupportRoute/RSSBlock";

const linkSettings = { rel: "noopener noreferrer" };

const PageWrapper = styled.div`
  > .Polaris-Page--fullWidth {
    max-width: 64rem;
  }
`;

const HelpCardsWrapper = styled.div`
  & > .Polaris-LegacyStack > .Polaris-LegacyStack__Item {
    width: 457px;
  }
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ListWrapper = styled.div`
  color: #3773d9;

  .Polaris-List {
    padding-left: 20px;

    .Polaris-List__Item {
      margin-bottom: 0px;
    }
  }
`;

const SectionWrapper = styled.div`
  .Polaris-LegacyCard__Section {
    max-width: 417px;
  }
`;

const SubscriptionsTableWrapper = styled.div`
  margin-top: 20px;

  .Polaris-Page {
    padding: 0;

    .Polaris-Page-Header {
      display: none;
    }
  }
`;

const FreeTrialBannerWrapper = styled.div`
  .Polaris-Badge {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const SectionTitleWrapper = styled.div`
  margin-bottom: 20px;
`;

function getDaySuffix(day: number): string {
  if ([11, 12, 13].includes(day)) {
    return "th";
  }

  const lastDigit = day % 10;
  return { 1: "st", 2: "nd", 3: "rd" }[lastDigit] ?? "th";
}

/**
 * Format the date as "January 30th"
 */
function formatLuxonDateTime(d: DateTime): string {
  if (!d.isValid) {
    return "";
  }

  return `${d.toFormat("MMMM")} ${d.day}${getDaySuffix(d.day)}`;
}

export function AdminHomeRoute(): JSX.Element {
  const activeOrg = useActiveOrganizationSelector();
  const { addToast } = useToast();

  const [sellingPlanGroups, setSellingPlanGroups] = useState<ISmartrrSellingPlanGroup[]>([]);

  const dispatch = useSmartrrVendorDispatch();
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const isFreeTrial = activePlan?.planName === "Free Trial";
  const trialPeriodEnd = isFreeTrial && formatLuxonDateTime(DateTime.fromISO(activePlan.trialPeriodEnd));

  const { todayTimestamp, tomorrowTimestamp } = getTomorrowTimestampRange();
  const { startTimestamp, endTimeStamp } = getOneWeekTimestampRange();

  const fetchSellingPlans = useCallback(async (bustCache: boolean) => {
    const sellingPlanGroups = await getSellingPlanGroups(bustCache);
    if (!sellingPlanGroups) {
      addToast("Error fetching selling plan groups");
      return;
    }
    setSellingPlanGroups(sellingPlanGroups);
  }, []);

  useEffect(() => {
    dispatch(syncAccountPlans());
    dispatch(loadAccountPlanConfigs());
    fetchSellingPlans(false);
  }, []);

  const trialDaysLeft = useMemo(() => {
    const today = new Date();
    if (activePlan?.trialPeriodEnd) {
      return getDaysBetweenDates(today, new Date(activePlan.trialPeriodEnd));
    }
    return 0;
  }, [activePlan]);

  const badgeMarkup = pluralize(trialDaysLeft, "day");

  return (
    <React.Fragment>
      <PageWrapper>
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              {!!isFreeTrial && (
                <React.Fragment>
                  <FreeTrialBannerWrapper>
                    <Banner title="Free trial" icon={ClockMajor} status="info">
                      <Badge status="info">{`${badgeMarkup} remaining`}</Badge>
                      <p>
                        <Button plain url="/admin/smartrr-account">
                          Select a plan
                        </Button>
                        &nbsp;by {trialPeriodEnd} in order to keep access to all your existing features.
                      </p>
                    </Banner>
                  </FreeTrialBannerWrapper>
                </React.Fragment>
              )}
            </Layout.Section>

            <Layout.Section>
              <SubscriptionsTableWrapper>
                <HomeTables
                  activeOrg={activeOrg}
                  sellingPlanGroups={sellingPlanGroups}
                  todayTimestamp={todayTimestamp}
                  tomorrowTimestamp={tomorrowTimestamp}
                  startTimestamp={startTimestamp}
                  endTimeStamp={endTimeStamp}
                />
              </SubscriptionsTableWrapper>
            </Layout.Section>
            <Layout.Section>
              <hr
                style={{
                  border: "var(--p-border-width-1) solid var(--p-color-border-subdued)",
                  margin: "20px 0",
                  borderTop: "0",
                }}
              />
            </Layout.Section>

            <Layout.Section>
              <SectionTitleWrapper>
                <Text variant="headingLg" as="h5">
                  Help & announcements
                </Text>
              </SectionTitleWrapper>
              <HelpCardsWrapper>
                <LegacyStack spacing="loose" wrap={false}>
                  <LegacyStack.Item fill>
                    <RSSBlock />
                  </LegacyStack.Item>
                  <LegacyStack.Item fill>
                    <CSMBlock />
                    <LegacyCard
                      title={
                        <HeaderWrapper>
                          <Text variant="headingMd" as="h6">
                            Top 10 FAQs
                          </Text>
                          <Button icon={ExternalMinor} external url="https://help.smartrr.com/docs/">
                            All help docs
                          </Button>
                        </HeaderWrapper>
                      }
                    >
                      <SectionWrapper>
                        <LegacyCard.Section>
                          <ListWrapper>
                            <List type="number">
                              {links.map(({ text, link }) => (
                                <List.Item key={link}>
                                  <Link url={link} target={"_blank"} {...linkSettings}>
                                    {text}
                                  </Link>
                                </List.Item>
                              ))}
                            </List>
                          </ListWrapper>
                        </LegacyCard.Section>
                      </SectionWrapper>
                    </LegacyCard>
                    <SupportFormContainer />
                  </LegacyStack.Item>
                </LegacyStack>
              </HelpCardsWrapper>
            </Layout.Section>
          </Layout>
        </Page>
      </PageWrapper>
    </React.Fragment>
  );
}
