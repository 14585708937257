import { isNil } from "lodash";

import { IUserSubscriptionCancellationProps } from "@vendor-app/app/AdminRoute/AdminChurnPreventionRoute/CancellationReasonsSettings";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const getUserCancellationReasons = async () => {
  const allResults: IUserSubscriptionCancellationProps[] = [];
  let pageNumber = 0;
  let totalPages = 1;
  const pageSize = 30;
  try {
    while (pageNumber < totalPages) {
      const res = await typedFrontendVendorApi.getReq("/user-subscription-cancellation", {
        query: {
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
        },
      });
      if (res.type === "success") {
        allResults.push(...res.body.data);
        totalPages = res.body.totalPages;
        pageNumber++;
      }
    }

    return allResults.filter(
      userCancellation =>
        !isNil(userCancellation.st.shopifyId) &&
        !isNil(userCancellation.st.custRel?.firstName) &&
        !isNil(userCancellation.st.custRel?.lastName)
    );
  } catch {
    return [];
  }
};
