import { IS_SANDBOX_FRONTEND, IS_STAGING_FRONTEND } from "@smartrr/shared/constants";
import { Navigate, createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { AdminHomeRoute } from "@vendor-app/app/AdminRoute/AdminHomeRoute";
import useSubscriptionEligibility from "@vendor-app/app/AdminRoute/AdminSetupRoute/Tabs/SubscriptionSetup/libs/hooks/useSubscriptionEligibility";

export const Route = createLazyFileRoute("/admin/home")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const isSuperUser = useIsSuperUserSelector();
  const { eligibleForSubscriptions } = useSubscriptionEligibility();

  if (!eligibleForSubscriptions && !isSuperUser && !(IS_STAGING_FRONTEND || IS_SANDBOX_FRONTEND)) {
    return <Navigate to="/admin/setup" />;
  }
  if (isSuperUser) {
    return <AdminHomeRoute />;
  }

  return <Navigate to="/admin/subscriptions" />;
}
