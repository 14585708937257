import { ICustomerRelationship } from "@smartrr/shared/entities/CustomerRelationship";
import {
  IPurchaseStateShallow,
  IPurchaseStateWithCustomerRelationship,
} from "@smartrr/shared/entities/PurchaseState";

import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";

export const openSubscriptionDetailsInNewTab = (
  selectedResources: string[],
  purchaseStates: (
    | IPurchaseStateWithCustomerRelationship
    | (IPurchaseStateShallow & { custRel?: ICustomerRelationship })
  )[]
) => {
  for (const cpsId of selectedResources) {
    const cps = purchaseStates.find(cps => cps.id === cpsId);

    if (!cps || !cps.custRel) {
      continue;
    }

    const custRelShopifyId = cps.custRel?.shopifyId;
    const subscriptionShopifyId = cps.shopifyId;

    if (!custRelShopifyId || !subscriptionShopifyId) {
      navigateToSubscriptionDetails(cps.id, true);
      continue;
    }

    navigateToSubscriptionDetails(subscriptionShopifyId, true);
  }
};
