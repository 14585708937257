import { IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";
import { uniqBy } from "lodash";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { IFailedBillingsInfo, getFormattedWarningText } from "../..";

export const getFailedPurchaseStatesAndFailedBills = async () => {
  let failedPurchaseStates: IFailedBillingsInfo[],
    failedBills: IBillWithPurchStAndCustomerRelationship[] = [];

  const billsRes = await typedFrontendVendorApi.getReq("/bill/failed/up-to-date");
  if (billsRes.type === "success") {
    failedBills = billsRes.body.failedBills;
  }

  const stRes = await typedFrontendVendorApi.getReq("/failed-billings/");
  if (stRes.type === "success") {
    failedPurchaseStates = stRes.body;
    //filtering purchaseStates and bills for cps.id and extracting an error message from bills and states
    //then setting them for modal view
    return uniqBy(
      [
        ...failedPurchaseStates,
        ...failedBills.map(bill => ({
          custRel: {
            firstName: bill.st.custRel?.firstName,
            lastName: bill.st.custRel?.lastName,
            shopifyId: bill.st.custRel?.shopifyId,
          },
          error: getFormattedWarningText(bill.error),
          shopifyId: bill.st.shopifyId,
        })),
      ],
      state => state.shopifyId
    );
  }

  return [];
};
